import {
  determineNavRouteOrUrl,
  filterNavTabs,
  getAdminAppLink,
  getColdFusionLink,
  getColdFusionLinkAppVersion,
  isAnnuityOnly,
  isStructuredProductsOnly,
} from "./navTabUtils";
import { ROUTE_KEYS } from "./routes";
import { BaseRouteType, FrontEndLibraries, NavTabType, ProductTypesType, RouteChild, UserPermissionsType } from "../global-nav.types";
import { DisplayDeskConfig } from "../global.nav.utils";
import { CustomWidgetPermissions, WidgetPermissions } from "./widget-permissions.types";
import { CHPermissions } from "./creation-hub.types";
import { CommonTranslationKeys } from "./translation/translation-keys";
import { UserDisplayConfig } from "@common/models/user";

export const loadDefaultNavTabs = (frontEndLibrary: FrontEndLibraries): NavTabType[] => [
  buildHomeTab(frontEndLibrary, []),
];

export const buildHomeTab = (frontEndLibrary: FrontEndLibraries, userPermissions: UserPermissionsType): NavTabType => ({
  title: 'HOME',
  icon: "tachometer-alt",
  ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.HOME, userPermissions),
});

export const buildNavTabs = (
  displayConfig: DisplayDeskConfig | undefined,
  userPermissions: UserPermissionsType,
  userTrainingDataProductTypes: ProductTypesType,
  frontEndLibrary: FrontEndLibraries,
  isProduction: boolean,
  company?: string | null,
  accessPermissions?: string[] | null
): NavTabType[] => {
  const createTab = buildCreateNavTab(accessPermissions, frontEndLibrary, userPermissions);
  const allNavRoutes: NavTabType[] = [
    !displayConfig?.hideHomeMenu ? buildHomeTab(frontEndLibrary, userPermissions) : null, // nulls get filtered out && are just used for inline conditional logic
    createTab,
    {
      title: 'LEARN',
      icon: "graduation-cap",
      permissions: [WidgetPermissions.AccessLearningCenter],
      ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.LEARN, userPermissions),
    },
    !displayConfig?.hideProductMarketplaceMenu
      ? {
          title: 'MARKETPLACE',
          icon: "store",
          children: [
            ...generateDynamicMarketplaceRoutes(userTrainingDataProductTypes, frontEndLibrary, userPermissions, displayConfig),
          ],
        }
      : null, // nulls get filtered out && are just used for inline conditional logic
    {
      title: 'LIFECYCLE',
      icon: "recycle",
      children: [...generateDynamicLifeCycleMenu(userTrainingDataProductTypes, frontEndLibrary, userPermissions, displayConfig)],
    },
    {
      title: 'TRADE',
      permissions: [WidgetPermissions.MenuSecondaries],
      ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.TRADE, userPermissions),
    },
    {
      title: 'ADMIN',
      icon: "user-shield",
      permissions: [WidgetPermissions.MenuAdmin],
      children: [
        {
          title: 'Company Administration',
          permissions: [WidgetPermissions.MenuAdmin],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.COMPANY_ADMIN, userPermissions),
        },
        {
          title: 'Company Relationship Mapping',
          permissions: [WidgetPermissions.MenuAdminCompanyRelationshipMapping],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.COMPANY_RELATIONSHIP, userPermissions),
        },
        {
          title: 'Company Configuration',
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.COMPANY_CONFIG, userPermissions),
        },
        {
          title: 'Admin App',
          permissions: [WidgetPermissions.MenuAdmin],
          url: getAdminAppLink(),
        },
        {
          title: 'Users',
          permissions: [WidgetPermissions.MenuAdminUsers],
          url: getColdFusionLinkAppVersion("AccountsContainer", null),
        },
        {
          title: 'Return Description Tool',
          permissions: [WidgetPermissions.MenuAdminReturnDescription],
          url: getColdFusionLink("productComparisionReturnDescription", null),
        },
        {
          title: 'Web Content',
          permissions: [WidgetPermissions.MenuAdminWebContent],
          url: getColdFusionLink("salesmarketingspace/webcontent", null),
        },
        {
          title: 'Program Issuer Map',
          permissions: [WidgetPermissions.MenuAdminProgramIssuerMap],
          url: getColdFusionLink("salesmarketingspace/programtoissuermap", null),
        },
        {
          title: 'Focus List Video',
          permissions: [WidgetPermissions.MenuAdminWebFocusListVideo],
          url: getColdFusionLink("inventory/videostructurecontent", null),
        },
        {
          title: 'Marketing Email Tracking Upload',
          permissions: [WidgetPermissions.MenuAdminMarketingEmailTrackingUpload],
          url: getColdFusionLink("home", "MarketingEmailTracking"),
        },
        {
          title: 'All Maturing Products',
          permissions: [WidgetPermissions.MenuAdminAllMaturingProducts],
          url: getColdFusionLink("home", "AllMaturingProducts"),
        },
        {
          title: 'Compliance Trainings Export',
          permissions: [WidgetPermissions.MenuAdminComplianceTrainingsExport],
          url: getColdFusionLink("home", "ComplainceTrainingExport"),
        },
        {
          title: 'Comparable Structure Types Configuration',
          permissions: [WidgetPermissions.MenuAdminComparableStructureTypesConfiguration],
          url: getColdFusionLink("home", "ComparableStructureConfiguration"),
        },
        {
          title: 'PCT Issuer Preferences',
          permissions: [WidgetPermissions.MenuAdminPCTIssuerPreferences],
          url: getColdFusionLink("home", "IssuerPreference"),
        },
        {
          title: 'PCT Client Preferences',
          permissions: [WidgetPermissions.MenuAdminPCTClientPreferences],
          url: getColdFusionLink("home", "ClientPreference"),
        },
        {
          title: 'Clients',
          permissions: [WidgetPermissions.MenuAdminClients],
          url: getColdFusionLink("clientsContainer", null),
        },
        {
          title: 'Daily Orders Export',
          permissions: [WidgetPermissions.MenuAdminDailyOrdersExport],
          url: getColdFusionLink("ordersExport", null),
        },
        {
          title: 'Order Email Notifications',
          permissions: [WidgetPermissions.MenuAdminOrderEmailNotifications],
          url: getColdFusionLinkAppVersion("emailNotificationContainer", null),
        },
        {
          title: 'Historical Emails',
          permissions: [WidgetPermissions.MenuAdminHistoricalEmails],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.HISTORICAL_EMAILS, userPermissions),
        },
        {
          title: 'File Upload',
          permissions: [WidgetPermissions.MenuAdminFileUpoad],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.FILE_UPLOAD, userPermissions),
        },
        {
          title: 'Audit Records',
          permissions: [WidgetPermissions.MenuAdminHistoricalEmails], // TODO: add new permission
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.AUDIT_RECORDS, userPermissions),
        },
        {
          title: 'Wholesaler Definition',
          permissions: [WidgetPermissions.MenuAdminWholesalerDefinition],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.WHOLESALER_DEFINITION, userPermissions),
        },
        {
          title: 'Wholesaler Mapping',
          permissions: [WidgetPermissions.MenuAdminWholesalerMapping],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.WHOLESALER_MAPPING, userPermissions),
        },
        {
          title: 'Product Upload',
          permissions: [WidgetPermissions.ProductCenter],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.PRODUCT_UPLOAD, userPermissions),
        },
        {
          title: 'Disclosure Document Upload',
          permissions: [WidgetPermissions.MarketplaceAdminFields],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.DISCLOSURE_DOC_UPLOAD, userPermissions),
        },
        {
          title: 'Market Data Maintenance',
          permissions: [WidgetPermissions.MenuAdmin],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.MARKET_DATA_MAINTENANCE, userPermissions),
        },
        {
          title: 'Issuer Confirmation',
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.ISSUER_CONFIRMATION, userPermissions),
        },
        {
          title: 'Email Configuration',
          permissions: [WidgetPermissions.MenuAdmin],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.EMAIL_CONFIG, userPermissions),
        },
        {
          title: 'Find RFQ',
          permissions: [WidgetPermissions.MenuAdmin],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.FIND_RFQ, userPermissions),
        },
        {
          title: 'Add CH Request to PDW',
          permissions: [WidgetPermissions.MenuAdmin],
          url: getColdFusionLink("adminAddProduct", null),
        },
        {
          title: 'Product Maintenance',
          permissions: [WidgetPermissions.ProductMaintenance],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.PRODUCT_MAINTENANCE, userPermissions),
        },
        {
          title: 'Notifications',
          permissions: [WidgetPermissions.MenuNotificationCenter],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.NOTIFICATIONS, userPermissions),
        },
        {
          title: 'Event Notifications',
          permissions: [WidgetPermissions.MenuAdmin],
          ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.EVENT_NOTIFICATIONS_ADMIN, userPermissions),
        },
      ],
    },
  ].filter((x) => x) as NavTabType[];

  const filteredTabs = filterNavTabs(allNavRoutes, userPermissions, isProduction, company)

  return filteredTabs;
};

const generateDynamicMarketplaceRoutes = (
  productTypes: ProductTypesType,
  frontEndLibrary: FrontEndLibraries,
  userPermissions: UserPermissionsType,
  deskConfig: DisplayDeskConfig,
): RouteChild[] => {
  const spHeader = {
    title: 'Structured Products',
    isSubHeader: true,
  };

  const spProductRoutes = {
    title: 'View Products',
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.STRUCTURED_PRODUCTS, userPermissions),
  };

  const spProductOrders = {
    title: 'Order Management',
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.ORDER_MANAGEMENT, userPermissions),
  };

  const marketplaceSettingsLink = {
    title: 'Market Place Settings',
    permissions: [WidgetPermissions.MenuMarketplaceSettings],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.MARKETPLACE_SETTINGS, userPermissions),
  };

  const spAccountUpload = {
    title: 'Account Upload',
    permissions: [WidgetPermissions.MenuPmConfigurationsKycRulesUpload],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.ACCOUNT_UPLOAD, userPermissions),
  };

  const spWholesalerMapping = {
    title: 'Wholesaler Mapping',
    permissions: [WidgetPermissions.MenuPmWholesalerMapping],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.WHOLESALER_MAPPING, userPermissions),
  };

  const anHeader = {
    title: 'Annuities',
    isSubHeader: true,
  };

  const annuityProductLink = {
    title: 'View Products',
    permissions: [WidgetPermissions.MenuMarketplaceAnnuity],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.ANNUITIES, userPermissions),
  };

  const anOrders = {
    title: 'Orders',
    permissions: [WidgetPermissions.MenuMarketplaceAnnuity],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.ORDERS, userPermissions),
  };

  if (isAnnuityOnly(productTypes)) {
    const marketPlaceRoutes = [anHeader, annuityProductLink];

    if(!deskConfig.hideAnnuityBuyButton) {
      marketPlaceRoutes.push(anOrders);
    }
    return marketPlaceRoutes;
  } else if (isStructuredProductsOnly(productTypes)) {
    return [spHeader, spProductRoutes, spProductOrders, marketplaceSettingsLink, spAccountUpload, spWholesalerMapping];
  } else {
    const marketPlaceRoutes =  [
      spHeader,
      spProductRoutes,
      spProductOrders,
      marketplaceSettingsLink,
      spAccountUpload,
      spWholesalerMapping,
      anHeader,
      annuityProductLink,
    ];

    if (!deskConfig.hideAnnuityBuyButton) {
      marketPlaceRoutes.push(anOrders);
    }
    return marketPlaceRoutes;
  }
};

const generateDynamicLifeCycleMenu = (
  productTypes: ProductTypesType,
  frontEndLibrary: FrontEndLibraries,
  userPermissions: UserPermissionsType,
  deskConfig?: DisplayDeskConfig
): RouteChild[] => {
  const childrenRoutes: RouteChild[] = [];

  const LIFECYCLE_SPS = {
    title: 'Structured Products',
    icon: "recycle",
    permissions: [WidgetPermissions.MenuLifecycleManager, WidgetPermissions.MenuCanViewIssuerDashboard],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.LIFECYCLE, userPermissions),
  };

  const LIFECYCLE_SPS_LEGACY = {
    title: 'Structured Products (Legacy)',
    permissions: [CustomWidgetPermissions.LEGACY_LIFECYCLE],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.LIFECYCLE_LEGACY, userPermissions),
  };

  const LIFECYCLE_ANNUITIES = {
    title: 'Annuities',
    permissions: [WidgetPermissions.AccessLifecycleManagerAnnuities],
    ...(deskConfig?.enableAnnuityLM2 ||
    userPermissions?.includes(WidgetPermissions.ANLMAccess)
      ? {
        ...determineNavRouteOrUrl(
          frontEndLibrary,
          ROUTE_KEYS.ANNUITIES_LIFECYCLE,
          userPermissions
        ),
      }
      : {
        ...determineNavRouteOrUrl(
          frontEndLibrary,
          ROUTE_KEYS.LIFECYCLE_AN,
          userPermissions
        ),
      }),
  };

  const EVENT_NOTIFICATIONS = {
    title: 'Event Notifications',
    permissions: [WidgetPermissions.EventConfirmationsApprover],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.EVENT_NOTIFICATIONS, userPermissions),
  };

  const ISSUER_CONFIRMATIONS = {
    title: 'Issuer Confirmations',
    permissions: [WidgetPermissions.EventConfirmationsIssuer],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.ISSUER_CONFIRMATIONS, userPermissions),
  };

  const LIKELIHOOD_OF_CALL = {
    title: 'Likelihood of Call',
    permissions: [WidgetPermissions.EventConfirmationsIssuer],
    ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.LIKELIHOOD_OF_CALL, userPermissions),
  };
  /**
   * Due to the way LM permission is assigned at a ROLE level
   * We need to filter out based on product assignments for the user
   */
  if (isAnnuityOnly(productTypes)) {
    // If a user only has AN products - only push AN eligible routes
    childrenRoutes.push(LIFECYCLE_ANNUITIES);
  } else if (isStructuredProductsOnly(productTypes)) {
    // If a user only has SP products - only push SP eligible routes
    childrenRoutes.push(LIFECYCLE_SPS);
    childrenRoutes.push(LIFECYCLE_SPS_LEGACY);
    childrenRoutes.push(EVENT_NOTIFICATIONS);
    childrenRoutes.push(ISSUER_CONFIRMATIONS);
    childrenRoutes.push(LIKELIHOOD_OF_CALL);
  } else {
    // If a user only has both AN & SP products - push all eligible routes
    childrenRoutes.push(LIFECYCLE_SPS);
    childrenRoutes.push(LIFECYCLE_SPS_LEGACY);
    childrenRoutes.push(LIFECYCLE_ANNUITIES);
    childrenRoutes.push(EVENT_NOTIFICATIONS);
    childrenRoutes.push(ISSUER_CONFIRMATIONS);
    childrenRoutes.push(LIKELIHOOD_OF_CALL);
  }

  return childrenRoutes;
};

const buildCreateNavTab = (
  accessPermissions: string[] | null | undefined,
  frontEndLibrary: FrontEndLibraries,
  userPermissions: UserPermissionsType
): NavTabType => {
  const baseMenuLink = {
    title: 'CREATE',
    icon: "hammer",
  };

  const config = [
    {
      permissions: [
        CHPermissions.CH2ReadOnly,
        CHPermissions.CH2Approver,
        CHPermissions.CH2ComplianceApprover,
        CHPermissions.ProgramAdvisor,
        CHPermissions.CH2Advisor,
        CHPermissions.Issuer,
        CHPermissions.CH2IssuerAccess,
      ],
      item: {
        title: 'Creation Hub 2.0',
        ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.CREATE, userPermissions),
      },
    },
    {
      permissions: [CHPermissions.GeneralPricingToolAccess],
      item: {
        title: 'Creation Hub 1.0',
        url: getColdFusionLink("home", "ProductCreation"),
      },
    },
    {
      permissions: [CHPermissions.LumaLitePricingToolAccess],
      item: {
        title: 'Creation Hub Lite',
        ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.CREATE_FORM, userPermissions),
      },
    },
    {
      permissions: [CHPermissions.CalendarDealPricingToolAccess],
      item: {
        title: 'Calendar Deal Pricing Tool',
        ...determineNavRouteOrUrl(frontEndLibrary, ROUTE_KEYS.CREATE_CALENDAR, userPermissions),
      },
    },
    {
      permissions: [CHPermissions.Issuer, CHPermissions.CH2IssuerAccess],
      item: {
        title: 'Request History',
        url: getColdFusionLink("home", "ProductCreation"),
      },
    },
    {
      permissions: [CHPermissions.GeneralPricingToolAccess, CHPermissions.Administrator],
      item: {
        title: 'Data Reporting',
        url: getColdFusionLink("home", "DataReporting"),
      },
    },
  ];

  const filteredConfig = config
    .filter(({ permissions }) => {
      return permissions.some((permission) => accessPermissions?.includes(permission));
    })
    .map(({ item }) => item);

  let data: BaseRouteType | Partial<NavTabType>;
  if (filteredConfig.length === 1) {
    data = filteredConfig[0];
  } else {
    const children: BaseRouteType[] = [];
    filteredConfig.forEach((configItem) => {
      configItem?.children ? children.push(...configItem.children) : children.push(configItem);
    });
    data = { children };
  }
  return {
    ...data,
    ...baseMenuLink,
  };
};
